// JS
import htmx from 'htmx.org/dist/htmx.esm.js'

window.htmx = htmx


document.body.addEventListener('htmx:beforeSwap', function (evt) {
  if (evt.detail.xhr.status === 404) {
    // alert the user when a 404 occurs (maybe use a nicer mechanism than alert())
    // alert('Error: Could Not Find Resource')
    console.error('Error: Could Not Find Resource')
  } else if (evt.detail.xhr.status === 422) {
    // allow 422 responses to swap as we are using this as a signal that
    // a form was submitted with bad data and want to rerender with the
    // errors
    //
    // set isError to 'false' to avoid error logging in console
    evt.detail.shouldSwap = true
    evt.detail.isError = false
  } else if (evt.detail.xhr.status === 418) {
    // if the response code 418 (I'm a teapot) is returned, retarget the
    // content of the response to the element with the id `teapot`
    evt.detail.shouldSwap = true
    evt.detail.target = window.htmx.find('#teapot')
  } else if (evt.detail.xhr.status === 500) {
    // if the response code 500 is returned, retarget the content of the
    // response to the element with the id `InternalError`
    evt.detail.shouldSwap = false
    // alert('Error: Internal Server Error. Unable to process request.')
    console.error('Error: Internal Server Error. Unable to process request.')
  }
})

// Handle flash messages and scroll to top
document.body.addEventListener('htmx:afterSwap', function (evt) {
  // Work around for scroll:top or scroll:window:top. For some reason,
  // these aren't consistent, so we just force it on our own.
  // Should be used with the usual `hx-swap` attribute (e.g. hx-swap="outerHTML scroll:top")
  const target = evt.detail.target
  if (target.hasAttribute('hx-swap')) {
    const swap = target.getAttribute('hx-swap')
    const vals = ['scroll:top', 'scroll:window:top']
    if (vals.some(val => swap.includes(val))) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }
})

